import React, { useState } from 'react';
import { Check, ChevronDown, Info, X } from 'react-feather';
import ServiceNoteModal from 'app/SchedulerDashboard/ServiceNoteModal';
import classnames from 'classnames';
import { Collapse, Card, CardHeader, CardBody, CardTitle, Button, Modal } from 'reactstrap';
import Checkbox from 'components/@vuexy/checkbox/CheckboxesVuexy';
import 'assets/scss/CustomCollapse.scss';

const ServiceModal = ({ isOpen = false, service = {}, toggle = () => {} }) => {
  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <Card className="m-0">
        <CardBody>
          <CardTitle className="mb-1">
            <div>{service?.title}</div>
            {!!toggle && <X onClick={toggle} size={25} className="collapse-btn-close" />}
            <hr />
          </CardTitle>
          <div>{service?.description}</div>
        </CardBody>
      </Card>
    </Modal>
  );
};

const CustomCollapseItem = ({
  collapseItem,
  handleUpdateService,
  handleAddToSelectedService,
  handleRemoveService,
}) => {
  const [appointmentCode, setAppointmentCode] = useState(collapseItem || {});
  const [isSelected, setIsSelected] = useState(false);
  const [status, setStatus] = useState('Closed');
  const [showServiceModal, setShowServiceModal] = useState(false);
  const [showNoteModal, setShowNoteModal] = useState(false);
  const [currentCode, setCurrentCode] = useState(null);

  const toggleCollapse = () => {
    setIsSelected(!isSelected);
  };
  const onEntered = () => {
    if (isSelected) setStatus('Opened');
  };
  const onEntering = () => {
    if (isSelected) setStatus('Opening');
  };

  const onExited = () => {
    if (isSelected) setStatus('Closed');
  };

  const onExiting = () => {
    if (isSelected) setStatus('Closing');
  };

  const handleInfoClick = (code) => {
    setCurrentCode(code);
    setShowServiceModal((state) => !state);
  };
  const handleNoteClick = (code) => {
    setCurrentCode(code);
    setShowNoteModal((state) => !state);
  };
  const handleAddService = (e, code) => {
    if (e.target.checked) {
      handleAddToSelectedService(code);
      setAppointmentCode((prev) => {
        return { ...prev, checked: true };
      });
    } else {
      handleRemoveService(code);
      setAppointmentCode((prev) => {
        return { ...prev, checked: false };
      });
    }
  };

  const handleChangeNotes = (value) => {
    setCurrentCode({ ...currentCode, customerNotes: value });
    setAppointmentCode((prev) => {
      return { ...prev, customerNotes: value };
    });
    handleUpdateService(currentCode.id)('customerNotes', value);
  };

  return (
    <>
      <Card
        className={classnames({
          'collapse-collapsed': status === 'Closed' && !isSelected,
          'collapse-shown': status === 'Opened' && isSelected,
          closing: status === 'Closing' && !isSelected,
          opening: status === 'Opening' && isSelected,
          'm-1': true,
          'rounded': true,
        })}
      >
        <CardHeader onClick={() => toggleCollapse(collapseItem.id)}>
          <CardTitle className="lead collapse-title collapsed">{collapseItem.title}</CardTitle>
          <ChevronDown size={15} className="collapse-icon" />
        </CardHeader>
        <Collapse
          isOpen={isSelected}
          onEntering={onEntering}
          onEntered={onEntered}
          onExiting={onExiting}
          onExited={onExited}
        >
          <CardBody className="p-0">
            <div className="container">
              <div className="row">
                <div className="col-12">
                  <div className="d-flex p-1 m-1 collapse-item justify-content-between align-items-center">
                    <Checkbox
                      color="primary"
                      icon={<Check className="vx-icon" size={16} />}
                      label={appointmentCode.title}
                      defaultChecked={false}
                      onClick={(e) => handleAddService(e, appointmentCode)}
                    />
                    <div>
                      {appointmentCode.checked && (
                        <Button
                          style={{ padding: '0.5rem 1rem' }}
                          color="primary"
                          onClick={() => handleNoteClick(appointmentCode)}
                        >
                          Add Note
                        </Button>
                      )}
                      <span className="ml-1" onClick={() => handleInfoClick(appointmentCode)}>
                        <Info />
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </CardBody>
        </Collapse>
      </Card>
      <ServiceModal
        service={currentCode}
        isOpen={showServiceModal}
        toggle={() => setShowServiceModal((state) => !state)}
      />
      <ServiceNoteModal
        service={currentCode}
        isOpen={showNoteModal}
        toggle={() => setShowNoteModal((state) => !state)}
        onChange={(value) => handleChangeNotes(value)}
      />
    </>
  );
};

export default CustomCollapseItem;
